// import {render} from 'preact';
import 'overlayscrollbars/overlayscrollbars.css';

import '../styles/important.scss';

import '../styles/index.scss';

import {
    OverlayScrollbars,
    ClickScrollPlugin
} from 'overlayscrollbars';

// window.render = render;


window.document.addEventListener("DOMContentLoaded", function () {
    OverlayScrollbars.plugin(ClickScrollPlugin);

    OverlayScrollbars(
        document.body,
        {
            scrollbars: {
                // clickScroll: true,
                visibility: "visible",
            },
        },
    );
});

(function () {
    console.log("Initializing init component");
})();


$(function () {
    $('.navbar-burger').each(function () {
        if (!$(this).data('initialized')) {
            $(this).data('initialized', true);
            $(this).click(function () {
                const target = $(this).data('target');
                $(`#${target}`).toggleClass('is-active');
            });
        }
    });
});